<template>
  <img :src="images[url] || url" />
</template>
<script>
const files = [
  'about/about_01.png',
  'about/about_02.jpg',
  'about/about_03.jpg',
  'about/about_04.png',
  'about/img2_01.png',
  'about/img2_02.png',
  'about/img2_03.png',
  'about/img2_04.png',
  'site_03.png',
  'site_08.png',
  'site_09.png',
  'site_12.png',
  'site_21.png',
  'site_21_pb.png',
  'site_22.png',
  'site_22_pb.png',
  'site_22_es.jpg',
  'site_22_ind.jpg',
  'team_ind.jpg',
  'site_22_tur.jpg',
  'team_tur.jpg',
  'site_27.png',
  'site_28.png',
  'site_29.png',
  'site_30.png',
  'Head/0.png',
  'Head/1.png',
  'Head/2.png',
  'Head/3.png',
  'Head/4.png',
  'Head/5.png',
  'Head/6.png',
  'Head/7.png',
  'Head/8.png',
  'Head/9.png',
  'Head/10.png',
  'Head/11.png',
  'Head/12.png',
  'Head/13.png',
  'Head/14.png',
  'Head/15.png',
  'Head/16.png',
  'Head/17.png',
  'Head/18.png',
  'Head/19.png',
  'Head/20.png',
  'Head/21.png',
  'Head/22.png',
  'Head/23.png',
  'Head/24.png',
  'Head/25.png',
  'Head/26.png',
  'Head/27.png',
  'Head/28.png',
  'Head/29.png',
  'Head/30.png',
  'Head/31.png',
  'Head/32.png',
  'pay_01.png',
  'level_01.png',
  'level_02.png',
  'level_03.png',
  'level_04.png',
  'level_05.png',
  'level_06.png',
  'level_07.png',
  'level_08.png',
  'Public/site_01.png',
  'site_41.png',
  'site_42.png',
  'site_43.png',
  'site_44.png',
  'site_45.png',
  'site_45_pb.png',
  'site_46.png',
  'site_46_pb.png',
  'site_47.png',
  'site_47_pb.png',
  'qr.png',
  'undraw.png'
];

const images = {};

for (let file of files) {
  images[file] = require(`@/Content/images${
    /^(\/)/.test(file) ? '' : '/'
  }${file}`);
}

export default {
  props: ['url'],
  setup() {
    return {
      images,
    };
  },
};
</script>
